.createButton {
    background: linear-gradient(90deg, rgb(124, 195, 241) 0%, rgb(216, 107, 222) 77%, rgb(219, 104, 220) 100%);
    border-color: linear-gradient(90deg, rgb(124, 195, 241) 0%, rgb(216, 107, 222) 77%, rgb(219, 104, 220) 100%);

    &:hover,
    &:active,
    &:focus {
        background: linear-gradient(270deg, rgb(216, 161, 255) 0%, rgb(216, 107, 222) 77%, rgb(208, 82, 247) 100%);
        border-color: linear-gradient(90deg, rgb(124, 195, 241) 0%, rgb(216, 107, 222) 77%, rgb(219, 104, 220) 100%);
    }
}